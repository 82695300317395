@import "@/assets/scss/var.scss";
.cart-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    > div {
        flex: 1;
    }
    > .cart-item__left {
        flex: 4;
        text-align: left;
    }
    > div:last-child {
        text-align: right;
    }
    .cart-item__left.is-pointer {
        cursor: pointer;
        .cart-item__left-name:hover {
            color: $color-primary;
        }
    }
    &-check {
        flex: unset !important;
        margin-right: 15px !important;
    }
    &__left {
        display: flex;
        align-items: center;
        position: relative;

        > div {
            margin-right: 5px;
        }
        // text-align: left;
        // // flex: 2;
        // flex: 4 !important;
        &-info {
            display: flex;
            align-items: center;
            .cart-cart__left-tag {
                margin-right: 5px;
            }
        }
        

        &-img {
            width: 80px;
            height: 80px;
            min-width: 80px;
        }
        &-img.is-stockout {
            &::after {
                content: "";
                display: block;
                width: 80px;
                height: 80px;
                position: absolute;
                left: 0;
                top: 0;
                background: rgba(0, 0, 0, 0.15);
            }
            &::before {
                content: "缺貨";
                color: #fff;
                text-align: center;
                line-height: 25px;
                font-size: 12px;
                display: block;
                width: 80px;
                height: 25px;
                position: absolute;
                left: 0;
                bottom: 0;
                background: #333;
                z-index: 1;
            }
        }

        &-btn {
            padding: 7px;
            margin-top: 10px;
        }
    }
    &__price {
        &-origin {
            color: #999;
            font-style: italic;
            text-decoration: line-through;
            margin-right: 5px;
        }
    }
}
.skuPopover {
    /deep/ .el-dialog__body {
        .skuPopover-num {
            > div {
                margin-bottom: 20px;
            }
        }
        .skuPopover-btn {
            text-align: center;
        }
    }
}
.cart-item-suit {
    padding: 0 30px;
    margin: 10px 0;
    // color: #999;
    .cart-item {
        &__left {
            display: flex;
            align-items: center;
            position: relative;
            > div {
                margin-right: 5px;
            }
            // text-align: left;
            // // flex: 2;
            // flex: 4 !important;
            &-img {
                width: 50px;
                height: 50px;
                min-width: 50px;
            }
        }
    }
    .cart-cart__left-tag {
        margin-right: 5px;
    }
}
