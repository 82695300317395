@import "@/assets/scss/var.scss";
.cart {
    background: #f7f8fa;
    /deep/ .el-checkbox__inner {
        width: 20px;
        height: 20px;
    }
    /deep/ .el-checkbox__inner::after {
        height: 10px;
        left: 7px;
        top: 2px;
    }

    &-content {
        // background: #fff;
        // padding: 20px;
        .cpgCartList,
        .soldOutCart {
            background: #fff;
        }
        .section {
            margin-bottom: 10px;
            .cart-goods {
                background: #fff;
                padding: 20px;
                margin-top: 10px;
                // padding-bottom: 15px;
                // border-bottom: 1px solid #efefef;
            }
            .cart-compose {
                margin-top: 0px;
            }
            // .cart-goods:last-child {
            //     border-bottom: none;
            // }
        }
    }
    .shopcart-head {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        padding: 20px;
        padding-bottom: 15px;
        border-bottom: 1px solid #efefef;
        .more:hover {
            cursor: pointer;
            color: $color-error;
        }
        .title {
            display: flex;
            align-items: center;
            .el-tag {
                margin-right: 10px;
            }
        }
    }
    .cart-header {
        margin: $offset-v 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px;
        text-align: center;
        .cart-header-commodity {
        }
        &-selectAll {
            .el-checkbox /deep/ {
                .el-checkbox__label {
                    font-size: 16px;
                    color: #000;
                }
            }
        }
        // &-num {
        //     text-align: center;
        // }
        // &-price {
        //     text-align: right;
        // }
        > div {
            flex: 1;
        }
        > div:first-child {
            flex: 4;
            text-align: left;
        }
        > div:last-child {
            text-align: right;
        }
        // .cart-header-left {
        //     flex: 4;
        //     text-align: left;
        // }
        &-commodity {
            margin-left: 50px;
        }
        // &-handleBtn {
        //     text-align: right;
        // }
    }

    &-title {
        color: $color-error;
        font-weight: bold;
    }
    .bottom-nav {
        z-index: 1;

        bottom: 0;
        border-right: 0;
        position: sticky;
        left: 0;
        right: 0;

        &_content {
            font-size: $fontsize;
            height: 60px;
            padding: 0 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-width: 960px;
            width: 1000px;
            background: #fff;
            border: 1px solid #f0f0f0;
            margin: auto;
            box-sizing: border-box;
            box-shadow: 0 -1px 8px rgba(0, 1, 1, 0.08);
        }
        &__left {
            display: flex;
            align-items: center;
            .el-checkbox {
                display: flex;
                align-items: center;
            }
            > div {
                margin-left: $offset-h;
            }
            &-remove {
                cursor: pointer;
            }

            .el-checkbox /deep/ {
                .el-checkbox__label {
                    font-size: 16px;
                }
            }
        }
        &__right {
            display: flex;
            align-items: center;
            > div,
            button {
                margin-left: 10px;
            }
            // &-button{
            //     height: 60px;
            //     width: 100px;
            // }
            &__total {
                &-price {
                    color: $color-primary;
                    font-size: 20px;
                }
                &-disc {
                    color: $color-primary;
                    font-size: 14px;
                }
            }
        }
    }
    .new-cart-set {
        border: 1px solid #f0f0f0;
        padding: 5px 15px;
        margin-bottom: 15px;
    }
    // .new-cart-list{
    //    /deep/ .new-cart-item{
    //         display: flex;
    //         justify-content: space-between;
    //         align-items: center;
    //         margin-bottom: $offset-v;
    //         flex-flow: wrap;
    //         &-top{
    //             height: 40px;
    //             line-height: 40px;
    //             margin-bottom: $offset-v;
    //             border-bottom: 1px solid #efefef;
    //             display: flex;
    //             justify-content: space-between;
    //             width: 100%;
    //             flex: unset;
    //             &__left{
    //                 // width: 35%;
    //                 display: flex;
    //                 align-items: center;
    //                 &-tag{
    //                     height: 25px;
    //                     line-height: 25px;
    //                 }
    //                 &-name{
    //                     margin-left: $offset-h;
    //                     width: 100%;
    //                     @extend .ellipsis
    //                 }
    //             }
    //             &__right{
    //                 display:flex;
    //                 >div{
    //                     margin-left: $offset-h;
    //                 }
    //                 &-disc{
    //                     color: $color-error;
    //                     cursor: pointer;
    //                 }

    //             }
    //         }
    //         &__left{
    //             display: flex;
    //             align-items: center;
    //             flex: 1;
    //             flex-basis: 30%;
    //             position: relative;
    //             &-img{
    //                 width: 80px;
    //                 height: 80px;
    //                 min-width: 80px;
    //                 margin-left: 10px;
    //             }
    //             &-img.is-active{
    //                 &::after{
    //                     content: "";
    //                     display: block;
    //                     width: 80px;
    //                     height: 80px;
    //                     position: absolute;
    //                     left: 0;
    //                     top: 0;
    //                     background: rgba(0,0,0,0.15);
    //                 }
    //                 &::before{
    //                     content: "补货中";
    //                     color: #fff;
    //                     text-align: center;
    //                     line-height: 25px;
    //                     font-size: 12px;
    //                     display: block;
    //                     width: 80px;
    //                     height: 25px;
    //                     position: absolute;
    //                     left: 0;
    //                     bottom: 0;
    //                     background: #333;
    //                     z-index: 1;
    //                 }
    //             }
    //             &-img.is-fail{
    //                 &::after{
    //                     content: "";
    //                     display: block;
    //                     width: 80px;
    //                     height: 80px;
    //                     position: absolute;
    //                     left: 0;
    //                     top: 0;
    //                     background: rgba(0,0,0,0.15);
    //                 }
    //                 &::before{
    //                     content: "已下架";
    //                     color: #fff;
    //                     text-align: center;
    //                     line-height: 25px;
    //                     font-size: 12px;
    //                     display: block;
    //                     width: 80px;
    //                     height: 25px;
    //                     position: absolute;
    //                     left: 0;
    //                     bottom: 0;
    //                     background: #333;
    //                     z-index: 1;
    //                 }
    //             }
    //             &-tag{
    //                 margin-left: $offset-h;
    //                 height: unset;
    //                 line-height: unset;
    //                 padding: 4px 8px;
    //             }
    //             &-name{
    //                 margin-left: $offset-h;
    //                 margin-right: $offset-h;
    //                 @extend .twoRows;
    //                 &--sku{
    //                     margin-bottom: 30px;
    //                     display: -webkit-box;
    //                     overflow: hidden;
    //                     text-overflow: ellipsis;
    //                     -webkit-line-clamp: 2;
    //                     -webkit-box-orient: vertical;
    //                 }
    //             }
    //             &-date{
    //                 color: $txt-color-grey;
    //                 font-size: $fontsize-sm;
    //             }
    //             &-tip{
    //                 color: $color-error;
    //             }
    //             &-edit{
    //                 padding: 5px;
    //                 margin-left: auto;
    //                 margin-right: 40px;
    //             }
    //         }
    //         &__price{
    //             flex: 1;
    //             text-align: right;
    //             &-o{
    //                 font-size: 14px;
    //                 text-decoration: line-through;
    //                 color: #666;
    //                 // margin-bottom: 5px;
    //             }
    //             &-n{
    //                 // color: $color-error;
    //             }
    //         }
    //         &__num{
    //             flex: 1;
    //             text-align: center;
    //             &-tip{
    //                 color: $color-error;
    //             }
    //         }
    //         &__handle{
    //             flex: 1;
    //             text-align: right;
    //             &__btn{
    //                >span{
    //                     cursor: pointer;
    //                }
    //             }
    //         }
    //         // &-addi{
    //         //     width: 100%;
    //         // }
    //         .el-input-number{
    //             .el-input {
    //                 border: 2px solid $border-primary;
    //             }
    //             .el-input__inner {
    //                 border: 0;
    //                 font-size: 16px;
    //                 font-weight: bold;
    //             }
    //             .el-input.is-active .el-input__inner,
    //             .el-input__inner:focus {
    //                 border-color: $border-primary;
    //                 outline: 0;
    //             }
    //             .el-input__inner:hover {
    //                 border-color: $border-primary;
    //             }
    //             .el-input-number__decrease:hover:not(.is-disabled)
    //                 ~ .el-input
    //                 .el-input__inner:not(.is-disabled),
    //             .el-input-number__increase:hover:not(.is-disabled)
    //                 ~ .el-input
    //                 .el-input__inner:not(.is-disabled) {
    //                 border-color: $border-primary;
    //             }
    //             .el-input-number__decrease:hover:not(.is-disabled)
    //                 ~ .el-input
    //                 .el-input__inner:not(.is-disabled),
    //             .el-input-number__increase:hover:not(.is-disabled)
    //                 ~ .el-input
    //                 .el-input__inner:not(.is-disabled) {
    //                 border-color: $border-primary;
    //             }
    //             .el-input-number__decrease,
    //             .el-input-number__increase {
    //                 position: absolute;
    //                 z-index: 1;
    //                 top: 2px;
    //                 width: 40px;
    //                 height: auto;
    //                 text-align: center;
    //                 background: #fff;
    //                 color: $color-primary;
    //                 cursor: pointer;
    //                 font-size: 14px;
    //             }
    //             .el-input-number__increase {
    //                 border-left: 0;
    //                 right: 5px;
    //             }
    //             .el-input-number__decrease {
    //                 border-right: 0;
    //                 left: 5px;
    //             }
    //             .el-input-number__decrease.is-disabled, .el-input-number__increase.is-disabled {
    //                 cursor: not-allowed !important;
    //             }
    //             .el-input.is-disabled .el-input__inner {
    //                 background-color: initial;
    //             }
    //         }
    //     }
    //     /deep/ .additionPopover{
    //         .el-dialog__header{
    //             padding: 10px 0px 0px 10px;
    //         }
    //         .cart-item{
    //             .cart-item__left{
    //                 flex-basis: 40%;
    //             }
    //         }
    //     }
    //     /deep/ .new-cart-item.cart-goods-suit{
    //         .el-image{
    //             width: 50px;
    //             height: 50px;
    //             min-width: 50px;
    //             margin: 5px;
    //             margin-left: 29px;
    //             margin-right: 15px;
    //         }
    //     }
    // }
}
